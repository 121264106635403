<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 20"
  >
    <path
      fill="currentColor"
      d="M5 12a1 1 0 0 1-.7-.3A1 1 0 0 1 4 11c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7a1 1 0 0 1-.7.3Zm4 0a1 1 0 0 1-.7-.3A1 1 0 0 1 8 11c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7a1 1 0 0 1-.7.3Zm4 0a1 1 0 0 1-.7-.3 1 1 0 0 1-.3-.7c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7a1 1 0 0 1-.7.3ZM2 20a2 2 0 0 1-1.4-.6A2 2 0 0 1 0 18V4c0-.5.2-1 .6-1.4A2 2 0 0 1 2 2h1V0h2v2h8V0h2v2h1c.6 0 1 .2 1.4.6.4.4.6.9.6 1.4v14c0 .6-.2 1-.6 1.4a2 2 0 0 1-1.4.6H2Zm0-2h14V8H2v10ZM2 6h14V4H2v2Zm0 0V4v2Z"
    />
  </svg>
</template>
